<template>
    <div id="app">
        <Quiz/>
    </div>
</template>

<script>
    import Quiz from './components/Quiz.vue'

    export default {
        name: 'App',
        components: {
            Quiz
        }
    }
</script>

<style>
    #app {
        font-family: BMWTypeNextTT, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @font-face {
        font-family: "BMWTypeNextTT";
        font-weight: normal;
        src: local("BMWTypeNextTT"),
        url(./assets/fonts/BMWTypeNextTT-Regular.ttf) format("truetype");
    }

    @font-face {
        font-family: "BMWTypeNextTT";
        font-weight: 400;
        src: local("BMWTypeNextTT"),
        url(./assets/fonts/BMWTypeNextTT-Light.ttf) format("truetype");
    }
</style>
<style>

</style>