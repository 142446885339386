<template>
    <div class="wrapper">

        <!-- STEP 1 START -->
        <transition name="slide-fade" mode="out-in">
            <template v-if="this.currentStep == 1">
                <div id="step-1">
                    <div class="container mx-auto h-full flex flex-col justify-between px-4">
                        <div>
                            <div class="py-8">
                                <a target="_blank" href="https://bmw-mcarsofia.bg/offer/uspeht-idva-na-chetiri-kolela?c=45072_MCarAcademy_2021_Q1_Quiz">
                                    <img alt="MCar" class="ml-auto mr-4" src="../assets/img/logo-white.png" />
                                </a>
                            </div>
                            <div><hr class="text-white" /></div>
                        </div>
                        <div>
                            <h1 class="text-white leading-10 text-4xl mb-12">УСПЕХЪТ ИДВА НА ЧЕТИРИ КОЛЕЛА. <br />ДОКАЖИ, ЧЕ СИ BMW ЕКСПЕРТ.</h1>
                            <a href="javascript:"
                               class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded mb-8 "
                               v-on:click="toStep2"
                            >Участвай тук</a>
                        </div>
                    </div>
                </div>
            </template>
        </transition>
        <!-- STEP 1 END -->



        <!-- STEP 2 START -->
        <transition name="slide-fade" mode="in-out">
            <template v-if="this.currentStep == 2">
                <div id="step-2" class="mb-20">
                    <div class="container mx-auto h-full flex flex-col justify-between">
                        <div class="mb-12">
                            <div class="py-8 flex content-between">
                                <img alt="MCar League" class="ml-4 mr-auto mt-3 hidden md:inline-block" style="height: 27px" src="../assets/img/mcar-league-label.png" />
                                <a target="_blank" href="https://bmw-mcarsofia.bg/offer/uspeht-idva-na-chetiri-kolela?c=45072_MCarAcademy_2021_Q1_Quiz">
                                    <img alt="MCar" class="mr-4 ml-auto" src="../assets/img/logo-gray.png" />
                                </a>
                            </div>
                            <div><hr class="text-gray-400" /></div>
                        </div>
                        <div class="flex lg:flex-row flex-col">
                            <div class="flex-grow px-4 md:px-16 pt-8 mb-20">
                                <div class="steps grid grid-cols-10 mx-auto">
                                    <div class="step text-center" v-for="q in [1,2,3,4,5,6,7,8,9,10]" v-bind:key="q" :class="{'border-b-4 border-blue-500' : q === currentQuestion + 1}">
                                        <div class="px-2 md:px-8 pb-2 border-b border-gray-200">{{ q }}</div>
                                    </div>
                                </div>

                                <transition name="slide-fade" mode="out-in">
                                    <div class="px-2 md:px-20 mx-auto mt-20" :key="this.questionSet[this.currentQuestion].question">
                                        <h2 class="text-2xl leading-8 text-gray-700 mb-8">
                                            {{ this.questionSet[this.currentQuestion].question }}
                                        </h2>
                                        <div class="py-3 border-b cursor-pointer hover:bg-gray-100" v-on:click="selectAnswer('b')" v-bind:class="{'bg-gray-100' : givenAnswers[currentQuestion] === 'b'}">
                                            <div class="letter inline-block w-16 mx-4 text-center font-bold">b</div>
                                            <div class="inline-block">{{ this.questionSet[this.currentQuestion].b }}</div>
                                        </div>
                                        <div class="py-3 border-b cursor-pointer hover:bg-gray-100" v-on:click="selectAnswer('m')" v-bind:class="{'bg-gray-100' : givenAnswers[currentQuestion] === 'm'}">
                                            <div class="letter inline-block w-16 mx-4 text-center font-bold">m</div>
                                            <div class="inline-block">{{ this.questionSet[this.currentQuestion].m }}</div>
                                        </div>
                                        <div class="py-3 border-b cursor-pointer hover:bg-gray-100 mb-12" v-on:click="selectAnswer('w')" v-bind:class="{'bg-gray-100' : givenAnswers[currentQuestion] === 'w'}">
                                            <div class="letter inline-block w-16 mx-4 text-center font-bold">w</div>
                                            <div class="inline-block">{{ this.questionSet[this.currentQuestion].w }}</div>
                                        </div>
                                        <template v-if="currentQuestion < 9">
                                            <a href="javascript:" v-on:click="addToScore" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded mb-8 ">Следващ</a>
                                        </template>
                                        <template v-else>
                                            <a href="javascript:" v-on:click="currentStep = 3" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded mb-8 ">Към резултатите</a>
                                        </template>
                                    </div>
                                </transition>
                            </div>
                            <div class="mx-auto flex-none" style="width: 300px;">

                                <Ad
                                        path="/1001631/300x600_right_all"
                                        :size="[300, 600]" id="div-gpt-ad-1612438957850-0"
                                />

                                <!-- /1001631/300x600_right_all -->
<!--                                <div id='div-gpt-ad-1612438957850-0' style='width: 300px; height: 600px;'>-->
<!--                                    <script>-->
<!--                                        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1612438957850-0'); });-->
<!--                                    </script>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </transition>
        <!-- STEP 2 END -->



        <!-- STEP 3 START -->
        <transition name="slide-fade" mode="in-out">
            <template v-if="this.currentStep == 3">
                <div id="step-3">
                    <div class="container mx-auto h-full flex flex-col justify-between">
                        <div>
                            <div class="py-8">
                                <a target="_blank" href="https://bmw-mcarsofia.bg/offer/uspeht-idva-na-chetiri-kolela?c=45072_MCarAcademy_2021_Q1_Quiz">
                                    <img alt="MCar" class="ml-auto mr-4" src="../assets/img/logo-white.png" />
                                </a>
                            </div>
                            <div><hr class="text-white" /></div>
                        </div>
                        <div class="mt-24 mb-12 mx-1">
                            <img src="../assets/img/logo-mcar-league.png" />
                        </div>

                        <div class="mx-6 max-w-6xl mb-12">
                            <h3 class="text-4xl uppercase mb-8 text-white font-bold">{{ result('title') }}</h3>
                            <p class="text-white text-xl mb-12">{{ result('text') }}</p>
                        </div>

                        <div class="subscription-wrapper mx-6">
                            <subscription />
                        </div>

                    </div>
                </div>
            </template>
        </transition>
        <!-- STEP 3 END -->

        <div id="cookie-consent" class="fixed bottom-0 bg-white w-full" v-if="showCookiePanel === true" style="box-shadow: 0 -1px 8px 0 rgb(0 0 0 / 50%);">
            <div class="container mx-auto px-4 my-8">
                <h3 class="mb-8 text-2xl font-light">Бисквитки</h3>
                <p class="mb-6 text-xs lg:text-base font-light">
                    Когато посещавате нашия уебсайт, събираме информация чрез използването на бисквитки. Те ни позволяват да подобряваме работата на уебсайта, както и да ви предоставяме премиум изживяване на него, показвайки ви най-релевантната информация и функционалности - правейки по-лесно за вас опознаването на истинската радост от шофирането, както и да ви предложим реклами, създадени за вас. Някои бисквитки са от изключително значение и не можем да ви предложим всички наши услуги без тях. Други бисквитки, включително такива на трети страни не са задължителни, но без тях нашият уебсайт може да не работи толкова добре. Също така, съдържанието може да не бъде максимално релевантно за вас. Кликвайки бутона ”ПРИЕМИ”, вие се съгласявате да използваме бисквитките. За повече информация и промяна на настройките можете да посетите нашата страницата с информация за политиката ни за бисквитките.
                </p>
                <div class="lg:flex lg:flex-row lg:justify-between">
                    <a href="https://bmw-mcarsofia.bg/politika-za-biskvitite" class="font-bold block lg:inline mb-4 lg:mb-0" target="_blank">Научете повече</a>
                    <button class="px-32 py-2 bg-blue-500 text-white font-bold text-sm block lg:inline" v-on:click="agreeToCookies">ПРИЕМИ</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Subscription from "./Subscription";
    import Ad from "./Ad";

    export default {
        name: 'Quiz',
        components: {
            Subscription, Ad
        },
        data: function () {
            return {
                "currentStep": "1",
                "questions": [
                    {
                        "question": "Компанията BMW не стартира с производството на автомобили, а с производството на:",
                        "b": "компютри",
                        "m": "самолетни двигатели",
                        "w": "кораби",
                        "answer": "m"
                    },
                    {
                        "question": "През 1970 г. BMW проектира състезателен автомобил под друга марка, това е:",
                        "b": "Lamborghini",
                        "m": "Porsche",
                        "w": "Ferrari",
                        "answer": "b"
                    },
                    {
                        "question": "BMW създава първия електрически автомобил за Олимпийските игри в Германия през:",
                        "b": "1933 г.",
                        "m": "1972 г.",
                        "w": "2017 г.",
                        "answer": "m"
                    },
                    {
                        "question": "\"Bayerische Motoren Werke\" стартира през:",
                        "b": "1915 г.",
                        "m": "1916 г.",
                        "w": "1917 г.",
                        "answer": "m"
                    },
                    {
                        "question": "През май 2013 г. Йохан Шварц чупи световния рекорд за най-продължителен дрифт в света с BMW M5. Колко км е продължил изминал?",
                        "b": "31 км",
                        "m": "82,5 км",
                        "w": "167,8 км ",
                        "answer": "m"
                    },
                    {
                        "question": "Как продължава известният слоган на BMW ... „The Ultimate ...“?",
                        "b": "Driving Universe",
                        "m": "Driving Experience",
                        "w": "Driving Vehicle",
                        "answer": "m"
                    },
                    {
                        "question": "От какво е вдъхновено синьо-бялото лого на BMW?",
                        "b": "Баварския флаг",
                        "m": "Небесните цветове, за да демонстрират лидерство в създаването на самолети",
                        "w": "Въртящо се витло",
                        "answer": "b"
                    },
                    {
                        "question": "Как се нарича системата за задвижване на всички колела, създадена от BMW?",
                        "b": "4Motion",
                        "m": "X-Drive",
                        "w": "Y-Quat",
                        "answer": "m"
                    },
                    {
                        "question": "Какво означава буквата „М“ на BMW М моделите?",
                        "b": "Motion",
                        "m": "Maximum",
                        "w": "Motorsport",
                        "answer": "w"
                    },
                    {
                        "question": "По време на представянето си през 1985 г., един седан е най-бързият модел на пазара. Това е модифицирана версия на серията 5 и все още е достъпен за продажба днес. Кой е той?",
                        "b": "BMW 520i",
                        "m": "BMW M1",
                        "w": "BMW M5",
                        "answer": "w"
                    },
                    {
                        "question": "Какво означава буквата „i“ в BMW i3 и BMW i8?",
                        "b": "Innovation",
                        "m": "International",
                        "w": "Indispensable",
                        "answer": "b"
                    },
                    {
                        "question": "През 2005 г. BMW представи иновативен модел, който може да бъде задвижван от каква форма на гориво?",
                        "b": "Hydrogen",
                        "m": "Nitrogen",
                        "w": "Solar power",
                        "answer": "b"
                    },
                    {
                        "question": "Кой модел от 1997 г. на BMW е проектиран с няколко от първите в света технологични иновации, като 6-степенна автоматична трансмисия и въздушни възглавници за главата?",
                        "b": "BMW 3 Серия",
                        "m": "BMW 5 Серия",
                        "w": "BMW 7 Серия",
                        "answer": "w"
                    },
                    {
                        "question": "Kак се нарича компютърната система, пусната за първи път в BMW 2001 7 Серия и сега оборудвана за много модели Rolls-Royce и BMW?",
                        "b": "iDrive",
                        "m": "iControl",
                        "w": "iCompute",
                        "answer": "b"
                    },
                    {
                        "question": "Сградата на централата на BMW е проектирана така, че да наподобява?",
                        "b": "4 цилиндров двигател",
                        "m": "скоростна кутия",
                        "w": "самолетен двигател",
                        "answer": "b"
                    },
                    {
                        "question": "4-цилиндровият двигател BMW M10 печели едно от най-големите състезания в света през 1983 г. Кое е то?",
                        "b": "Formula One World Championship",
                        "m": "Nascar",
                        "w": "24 Hours of Le Mans",
                        "answer": "b"
                    },
                    {
                        "question": "През коя година е създадена BMW Серия 3?",
                        "b": "1970 г.",
                        "m": "1975 г.",
                        "w": "1980 г.",
                        "answer": "m"
                    },
                    {
                        "question": "Кой ще бъде следващият BMW електрически модел?",
                        "b": "BMW iX",
                        "m": "BMW iCSL",
                        "w": "BMW iM",
                        "answer": "b"
                    },
                    {
                        "question": "През коя година BMW произвеждат първия Rolls-Royce Phantom?",
                        "b": "2003 г.",
                        "m": "2004 г.",
                        "w": "2005 г.",
                        "answer": "b"
                    },
                    {
                        "question": "През коя година се появява характерният за BMW Kidney Grille с модел BMW 303?",
                        "b": "1929 г.",
                        "m": "1933 г.",
                        "w": "1940 г.",
                        "answer": "m"
                    },
                    {
                        "question": "Какво е  Speed Limit Assist при BMW автомобилите?",
                        "b": "система за подпомагане на водача, която сканира и показва пътните знаци на таблото и в екстремна ситуация помага на водача да ограничи скоростта",
                        "m": "система за подпомагане на водача, която помага на автомобила да спре по-бързо в екстремна ситуация",
                        "w": "система за подпомагане на водача, която адаптира максималната скорост на автомобила към определена граница",
                        "answer": "w"
                    },
                    {
                        "question": "Как се нарича системата за подпомагане на водача, която се ориентира към превозното средство пред автомобила и автоматично спира или ускорява в зависимост от разстоянието между двата автомобила?",
                        "b": "distance control",
                        "m": "guidance assist provide support",
                        "w": "hill start assist",
                        "answer": "b"
                    },
                    {
                        "question": "Как се нарича характерната решетка в BMW автомобилите?",
                        "b": "Kidney grille",
                        "m": "Brain grille",
                        "w": "Lungs grille",
                        "answer": "b"
                    },
                    {
                        "question": "Какъв двигател е задвижвал спецификацията E36 BMW M3 в САЩ?",
                        "b": "S50",
                        "m": "S54",
                        "w": "S52",
                        "answer": "w"
                    },
                    {
                        "question": "Какво е BMW '700'?",
                        "b": "Концептуален модел от 1980 г.",
                        "m": "4-местен модел от 1950 г.",
                        "w": "двуместно boxer купе с двигател в задната част на автомобила ",
                        "answer": "w"
                    },
                    {
                        "question": "Настоящият слоган на BMW е?",
                        "b": "Радостта от шофирането",
                        "m": "Удобство по време на път",
                        "w": "Иновации на четири колела ",
                        "answer": "b"
                    },
                    {
                        "question": "През коя година излиза на пазара електрическият модел BMW i3?",
                        "b": "2016 г. ",
                        "m": "2017 г. ",
                        "w": "2018 г.",
                        "answer": "m"
                    },
                    {
                        "question": "През 1972 г. BMW създават BMW TURBO. Концептуален автомобил със средно разположен двигател, дигитално табло, врати тип „gull wing“, както и турбо двигател с производителност от 280 конски сили. Коло такива автомобила са произведени?",
                        "b": "1",
                        "m": "2",
                        "w": "3",
                        "answer": "m"
                    },
                    {
                        "question": "Кой BMW модел е известен като „Батмобилът“?",
                        "b": "BMW CSL",
                        "m": "BMW i8",
                        "w": "BMW Z4",
                        "answer": "b"
                    },
                    {
                        "question": "В обозначението на моделите на BMW се използват и буквени индекси, които обозначават характеристики на автомобила, в допълнение към серията и обема. Какво означава индексът \"d\"?",
                        "b": "Dynamic",
                        "m": "Diesel",
                        "w": "Driving",
                        "answer": "m"
                    }
                ],
                "promoQuestions": [
                    {
                        "question": "Какво е ценовото предимство на BMW Z4 sDrive30i, която се предлагат от М Кар София до края на февруари, 2021 година?",
                        "b": "40 238 ЛЕВА С ДДС",
                        "m": "55 238 ЛЕВА С ДДС",
                        "w": "63  238 ЛЕВА С ДДС",
                        "answer": "b"
                    },
                    {
                        "question": "Каква е месечната вноска за автомобил BMW СЕРИЯ 4 КУПЕ от М Кар София до края на февруари, 2021 година?",
                        "b": "1029 ЛЕВА С ДДС НА МЕСЕЦ",
                        "m": "1505 ЛЕВА С ДДС НА МЕСЕЦ",
                        "w": "1632 ЛЕВА С ДДС НА МЕСЕЦ",
                        "answer": "b"
                    },
                    {
                        "question": "Каква е първоначалната вноска за автомобил BMW i3 от М Кар София към момента?",
                        "b": "без първоначална вноска ",
                        "m": "2%",
                        "w": "1%",
                        "answer": "b"
                    }
                ],
                "results": {
                    "junior": {
                        "title": "JUNIOR",
                        "text": "Има още какво да научите за BMW. Докато опознавате марката, ние ви предлагаме безплатна консултация по телефона: +359 2 49 29 391\n" +
                            "Ще ви разкажем всичко, което ви интересува за марката BMW и най-добрите ни модели. Нашите експерти ще ви представят ексклузивните оферти, част от които можеш да разгледате и онлайн."
                    },
                    "advanced": {
                        "title": "ADVANCED",
                        "text": "Личи си коя е любимата ви марка, но ви трябва още малко, за да затвърдите познанията си. Свържете се с нас на тел: +359 2 49 29 391, за да отговорим на всичките ви въпроси и да ви запознаем с ексклузивните ни оферти. Mожете да разгледате атрактивните ни предложения и онлайн."
                    },
                    "pro": {
                        "title": "PRO",
                        "text": "Явно знаете отлично, кой е най-добрият автомобил. Свържете се с нас на тел.: +359 2 49 29 391 за да споделите страстта си към BMW с нашите експерти и да получите ексклузивни оферти за едни от най-добрите ни модели. Mожете да разгледате активните ни оферти и онлайн."
                    }
                },
                "currentQuestion": 0,
                "currentAnswer": 0,
                "score": 0,
                "givenAnswers": {},
                "showCookiePanel": false
            };
        },
        computed: {
            questionSet: function () {
                let pickedQuestions = this.getRandom(this.questions, 9);
                let pickedPromoQuestion = this.getRandom(this.promoQuestions, 1);
                return pickedQuestions.concat(pickedPromoQuestion);
            }
        },
        mounted() {
            this.checkCookieConsent();
        },
        methods: {
            toStep2() {
                this.currentStep = 2;
                window.gtag('event', 'click', {
                    'event_category': 'Quiz',
                    'event_label': 'To Step 2'
                });
            },
            getRandom(arr, n) {
                let result = new Array(n),
                    len = arr.length,
                    taken = new Array(len);
                if (n > len)
                    throw new RangeError("getRandom: more elements taken than available");
                while (n--) {
                    let x = Math.floor(Math.random() * len);
                    result[n] = arr[x in taken ? taken[x] : x];
                    taken[x] = --len in taken ? taken[len] : len;
                }
                return result;
            },
            selectAnswer(answer) {
                this.givenAnswers[this.currentQuestion] = answer;
                if (answer === this.questionSet[this.currentQuestion].answer) {
                    this.currentAnswer = 1;
                } else {
                    this.currentAnswer = 0;
                }
                this.$forceUpdate(); // hack. There's probably a better way with :key
            },
            addToScore() {
                if(!this.givenAnswers[this.currentQuestion]) {
                    return false;
                }
                this.score = this.score + this.currentAnswer;
                this.currentAnswer = 0;
                this.currentQuestion = this.currentQuestion + 1;
            },
            result(field) {
                let resultObject = {};
                if(this.score <= 4) {
                    resultObject = this.results.junior;
                } else if (this.score > 4 && this.score <= 7) {
                    resultObject = this.results.advanced;
                } else {
                    resultObject = this.results.pro;
                }
                return resultObject[field];
            },
            agreeToCookies() {
                localStorage.setItem('cookieConsent', 'yes');
                this.showCookiePanel = false;
            },
            checkCookieConsent() {
                let cc = localStorage.getItem('cookieConsent');
                if (cc !== 'yes') {
                    this.showCookiePanel = true;
                }
            }
        }
    }
</script>

